import { useEffect, useState } from "react"
import {
    getAccountById,
    getAccounts,
    settleAccount
} from "../../../api/endpoints/account"
import { Skeleton } from "../../../components/ui/skeleton"
import { PlatformTemplate } from "../../../components/layout/template"
import { Landmark, Loader2 } from "lucide-react"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../components/ui/dialog"
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "../../../components/ui/select"
import { getOwnersById, getSettlementAccountsByOwnerId } from "../../../api/endpoints/owner"
import TableAccounts from "./_components/table-account"
import { transferFee } from "../../../api/endpoints/admin"
import { formatToBRL } from "../../../utils/helpers/formated"
import { Button } from "../../../components/ui/button"
import { Input } from "../../../components/ui/input"
import Swal from "sweetalert2"

export type AccountProps = {
    accountId: string
    branch: string
    accountNumber: string
    type: string
    balance: number
    status: string
    owner: {
        id: string
        name: string
    }
}

export type Filters = {
    accountId: string
    accountNumber: string
    ownerId: string
    ownerName: string
    ownerDocument: string
    status: '' | 'Active' | 'Inactive' | 'Blocked'
    type: '' | 'checkingAccount' | 'salary' | 'savings' | 'transactional'
}

type SettlementAccountProps = {
    settlementAccountId: string;
    name: string;
    documentNumber: string;
    documentType: string;
    accountNumber: string;
    branchNumber: string;
    accountType: string;
    bankIspb: string;
    bankCode: string | null;
    bankName: string | null;
    createDate: string;
    updateDate: string;
}

export default function Accounts() {
    const [accounts, setAccounts] = useState<AccountProps[]>()
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [filters, setFilters] = useState<Filters>({
        accountId: '',
        accountNumber: '',
        ownerId: '',
        ownerName: '',
        ownerDocument: '',
        status: '',
        type: '',
    })
    const [appliedFilters, setAppliedFilters] = useState<Filters>(filters)
    // => Use Dialog control and data 
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null)
    const [accountSelected, setAccountSelected] = useState<AccountProps>()
    const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(true)
    const [transferAmount, setTransferAmount] = useState<number>(0)
    const [transferSettlementAccount, setTransferSettlementAccount] = useState<string>('')
    const [isTransferSubmit, setIsTransferSubmit] = useState<boolean>(false)
    const [transferOwner, setTransferOwner] = useState()
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
    const [transferSettlementAccounts, setTransferSettlementAccounts] = useState<SettlementAccountProps[]>([])
    const [rawValue, setRawValue] = useState<number>(0)
    const [isTransferFeeDialogOpen, setIsTransferFeeDialogOpen] = useState<boolean>(false)
    const [amountTransferFee, setAmountTransferFee] = useState<number>(0)

    const fetchAccounts = async (pageIndex: number, pageSize: number, filters: Filters) => {
        const response: any = await getAccounts({ pageIndex, pageSize, ...filters })
        setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
        if (response?.data?.items) {
            setAccounts(response?.data?.items)
        } else {
            setAccounts([])
        }
    }

    const fetchTransferGetData = async (id: string, ownerId: string) => {
        setTransferAmount(0)
        setIsLoadingDialog(true)
        const response = await getAccountById({ accountId: id })
        if (response?.data) {
            setAccountSelected(response.data)
        } else {
            setAccountSelected(undefined)
        }

        const responseOwner = await getOwnersById(ownerId)
        if (responseOwner.data) {
            setTransferOwner(responseOwner.data)
        } else {
            setTransferOwner(undefined)
        }

        const responseSettlementAccounts = await getSettlementAccountsByOwnerId(ownerId)
        if (responseSettlementAccounts.data) {
            setTransferSettlementAccounts(responseSettlementAccounts.data)
        } else {
            setTransferSettlementAccounts([])
        }

        return setIsLoadingDialog(false)
    }

    const handleTransfer = async () => {
        setIsTransferSubmit(true)
        const response = await settleAccount({
            accountId: String(selectedAccountId),
            data: {
                amount: transferAmount,
                settlementAccountId: transferSettlementAccount
            }
        })

        if (response.data.isSucceeded) {
            Swal.fire({
                icon: "success",
                title: "Transferência solicitada!",
                confirmButtonColor: "var(--gray-500)",
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Não foi possível realizar a solicitação de transferência.",
                confirmButtonColor: "var(--gray-500)",
            })
        }

        setIsDialogOpen(false)
        setIsTransferSubmit(false)
        setTransferOwner(undefined)
        setAccountSelected(undefined)
        setTransferSettlementAccount('')
        setTransferSettlementAccounts([])
        fetchAccounts(pageIndex, pageSize, appliedFilters)
    }

    useEffect(() => {
        fetchAccounts(pageIndex, pageSize, appliedFilters)
    }, [pageIndex, pageSize, appliedFilters])


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value.replace(/\D/g, '')
        const numericValue = Number(inputValue)

        if (accountSelected?.balance !== undefined && numericValue > accountSelected.balance) {
            setRawValue(accountSelected.balance)
            setTransferAmount(accountSelected.balance)
        } else {
            setRawValue(numericValue)
            setTransferAmount(numericValue)
        }
    }

    const selectedBank = transferSettlementAccounts.find((bank) => bank.settlementAccountId === transferSettlementAccount)

    const handleInputChangeTrasferFee = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value.replace(/\D/g, '')
        const numericValue = Number(inputValue)
        setAmountTransferFee(numericValue)
    }

    const handleTransferFee = async () => {
        const response = await transferFee({ accountId: String(selectedAccountId), amount: amountTransferFee })

        if (response.status === 200) {
            Swal.fire({
                icon: "success",
                title: "Solicitação de Transferência de Taxa realizado com sucesso.",
                confirmButtonColor: "var(--gray-500)",
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Não foi possível realizar a solicitação de transferência de Taxa.",
                confirmButtonColor: "var(--gray-500)",
            })
        }

        setAmountTransferFee(0)
    }

    return (
        <PlatformTemplate>
            <div className="w-full h-full flex flex-col">
                <div className="w-full flex items-center py-3 gap-2">
                    <Landmark size={20} />
                    <h1 className="text-[20px] font-semibold">Contas</h1>
                </div>
                {accounts ? (
                    <>
                        <TableAccounts
                            accounts={accounts}
                            setAccounts={setAccounts}
                            pageIndex={pageIndex}
                            setPageIndex={setPageIndex}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            totalPages={totalPages}
                            setTotalPages={setTotalPages}
                            filters={filters}
                            setFilters={setFilters}
                            appliedFilters={appliedFilters}
                            setAppliedFilters={setAppliedFilters}
                            onActionTransferFee={(id) => {
                                setSelectedAccountId(id)
                                setIsTransferFeeDialogOpen(true)
                            }}
                            onAction={(id, ownerId) => {
                                setSelectedAccountId(id)
                                setIsDialogOpen(true)
                                fetchTransferGetData(id, ownerId)
                            }}
                        />
                        <Dialog open={isDialogOpen}>
                            <DialogContent className="sm:max-w-[500px] min-h-[350px] [&>button]:hidden">
                                {!isLoadingDialog ?
                                    <div className="w-full h-full flex flex-col justify-between">
                                        <DialogHeader>
                                            <DialogTitle className="text-lg">Realizar transferência</DialogTitle>
                                            <DialogDescription className="h-16 flex flex-col items-start justify-center">
                                                <div className="flex items-center justify-center gap-1">
                                                    <p className="font-bold">Agência:</p>
                                                    <p>{accountSelected?.branch}</p>
                                                </div>
                                                <div className="flex items-center justify-center gap-1">
                                                    <p className="font-bold">Conta:</p>
                                                    <p>{accountSelected?.accountNumber}</p>
                                                </div>
                                            </DialogDescription>
                                        </DialogHeader>
                                        <div className="grid gap-6 py-4">
                                            <div>
                                                <p className="text-emerald-800 font-bold text-sm">Saldo:</p>
                                                <p className="text-emerald-600 font-semibold text-2xl">{formatToBRL(Number(accountSelected?.balance))}</p>
                                            </div>
                                            <div className="flex flex-col gap-3">
                                                <p>Transferir para:</p>
                                                <Select
                                                    disabled={isTransferSubmit}
                                                    value={transferSettlementAccount}
                                                    onValueChange={(event) => setTransferSettlementAccount(event)}
                                                >
                                                    <SelectTrigger className="w-2/3">
                                                        <SelectValue placeholder="Selecione um banco:" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectGroup>
                                                            <SelectLabel>Outros bancos:</SelectLabel>
                                                            {transferSettlementAccounts.map((bank: any) => (
                                                                <SelectItem value={bank.settlementAccountId}>{bank.name}</SelectItem>
                                                            ))}
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                            </div>
                                            <div className="flex items-center justify-between my-5">
                                                <p className="text-neutral-500 text-sm">Valor de transferência:</p>
                                                <Input
                                                    disabled={isTransferSubmit || Number(accountSelected?.balance) === 0}
                                                    className="w-1/2"
                                                    value={rawValue ? formatToBRL(rawValue) : ''}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <DialogFooter className="mt-10">
                                            <Button disabled={isTransferSubmit} variant="outline" onClick={() => {
                                                setRawValue(0)
                                                setIsDialogOpen(false)
                                            }}
                                            >
                                                Cancelar
                                            </Button>
                                            <Button
                                                disabled={
                                                    isTransferSubmit ||
                                                    Number(accountSelected?.balance) === 0 ||
                                                    transferAmount <= 0 ||
                                                    !transferSettlementAccount
                                                }
                                                className="bg-emerald-600"
                                                onClick={() => setIsConfirmDialogOpen(true)}
                                            >
                                                {isTransferSubmit
                                                    ? <span className="flex items-center justify-center gap-2">
                                                        <Loader2 className="animate-spin" size={15} />
                                                        Solicitando transferência...
                                                    </span>
                                                    : 'Confirmar'
                                                }
                                            </Button>
                                        </DialogFooter>
                                    </div>
                                    :
                                    <div className="w-full min-h-[350px] flex flex-col gap-3 items-center justify-center text-emerald-600">
                                        <Loader2 className="animate-spin" size={60} />
                                        <p className="w-[250px] text-center text-lg">Carregando informações de transferência...</p>
                                    </div>
                                }
                            </DialogContent>
                        </Dialog>
                        <Dialog open={isConfirmDialogOpen}>
                            <DialogContent className="[&>button]:hidden">
                                <DialogHeader>
                                    <DialogTitle>Confirmar Transferência</DialogTitle>
                                    <DialogDescription>
                                        <p className="text-sm font-medium text-gray-600">
                                            Você tem certeza que deseja realizar a transferência no valor de{" "}
                                            <strong className="text-green-500">{formatToBRL(transferAmount)}</strong>?
                                        </p>
                                        {selectedBank && (
                                            <div className="mt-5 rounded-md border border-gray-200 bg-gray-50 p-3 shadow-sm">
                                                <p className="text-sm font-semibold text-gray-700 mb-3">Detalhes do Destinatário</p>
                                                <div className="space-y-1 text-xs text-gray-600">
                                                    <p>
                                                        <strong className="font-medium text-gray-800">Destinatário:</strong> {selectedBank.name}
                                                    </p>
                                                    <p>
                                                        <strong className="font-medium text-gray-800">Documento:</strong>{" "}
                                                        {selectedBank.documentType} - {selectedBank.documentNumber}
                                                    </p>
                                                    <p>
                                                        <strong className="font-medium text-gray-800">Conta:</strong>{" "}
                                                        {selectedBank.accountNumber}
                                                    </p>
                                                    <p>
                                                        <strong className="font-medium text-gray-800">Agência:</strong>{" "}
                                                        {selectedBank.branchNumber}
                                                    </p>
                                                    <p>
                                                        <strong className="font-medium text-gray-800">Tipo de Conta:</strong>{" "}
                                                        {selectedBank.accountType}
                                                    </p>
                                                    <p>
                                                        <strong className="font-medium text-gray-800">Banco:</strong>{" "}
                                                        {selectedBank.bankName || <span className="italic text-gray-400">Não especificado</span>}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </DialogDescription>
                                </DialogHeader>
                                <DialogFooter>
                                    <Button
                                        variant="outline"
                                        onClick={() => {
                                            setRawValue(0)
                                            setIsConfirmDialogOpen(false)
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        className="bg-emerald-600"
                                        onClick={() => {
                                            handleTransfer();
                                            setIsConfirmDialogOpen(false);
                                        }}
                                    >
                                        Confirmar
                                    </Button>
                                </DialogFooter>
                            </DialogContent>
                        </Dialog>
                    </>
                ) : (
                    <Skeleton className="w-full h-full" />
                )}
                <Dialog
                    open={isTransferFeeDialogOpen}
                    defaultOpen={isTransferFeeDialogOpen}
                >
                    <DialogContent className="[&>button]:hidden">
                        <DialogHeader>
                            <DialogTitle>
                                Transferência de Taxa
                            </DialogTitle>
                            <DialogDescription className="flex items-center py-5 justify-start gap-3">
                                <p>Valor:</p>
                                <Input
                                    className="w-1/2"
                                    value={formatToBRL(amountTransferFee)}
                                    onChange={handleInputChangeTrasferFee}
                                />
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter>
                            <Button
                                variant='outline'
                                onClick={() => {
                                    setAmountTransferFee(0)
                                    setIsTransferFeeDialogOpen(false)
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                className="bg-emerald-600"
                                disabled={amountTransferFee === 0}
                                onClick={() => {
                                    setIsTransferFeeDialogOpen(false)
                                    handleTransferFee()
                                }}
                            >
                                Confirmar
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </div>
        </PlatformTemplate>
    )
}
