import { useEffect, useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../../../../components/ui/dialog"
import {
  Check,
  Fingerprint,
  HandCoins,
  Loader2,
  Printer,
  ReceiptText,
  Wallet
} from "lucide-react"
import { convertUTCToUserTimezone } from "../../../../../utils/helpers/date"
import { getUserTimezoneOffset } from "../../../../../utils/storage/auth"
import { getTransactionById } from "../../../../../api/endpoints/transaction"
import { PlatformTemplate } from "../../../../../components/layout/template"
import { TransactionProps } from "../../page"
import { TabsTransaction } from "../_components/tabs-transaction"
import { formatToBRL } from "../../../../../utils/helpers/formated"
import { useNavigate } from "react-router-dom"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { getPath } from "../../../../../utils/helpers/path"
import { Button } from "../../../../../components/ui/button"
import Logo from "../../../../../assets/lyralogo.svg"

export default function TransactionDetails() {
  const transactionId = getPath()
  const [transaction, setTransaction] = useState<TransactionProps | {}>()
  const [isExport, setIsExport] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const fetchTransactionById = async () => {
    setIsExport(true)
    const response = await getTransactionById({ transactionId })
    if (response?.data) {
      setTransaction(response?.data)
    } else {
      setTransaction({})
    }

    setIsLoading(false)
    setIsExport(false)
  }

  useEffect(() => {
    fetchTransactionById()
  }, [])

  const handlePrint = () => {
    setIsDialogOpen(false)
    const printContent = document.getElementById("transaction")
    const printWindow = window.open("", "_blank")
    if (printContent && printWindow) {
      const styles = Array.from(document.styleSheets)
        .map((styleSheet) => {
          try {
            return Array.from(styleSheet.cssRules)
              .map((rule) => rule.cssText)
              .join("\n")
          } catch {
            return ""
          }
        })
        .join("\n")

      printWindow.document.write(`
        <html>
          <head>
            <title>Comprovante de Transferência</title>
            <style>
              ${styles}
            </style>
          </head>
          <body>
            ${printContent.outerHTML}
          </body>
        </html>
      `)
      printWindow.document.close()
      printWindow.focus()
      printWindow.print()
      printWindow.close()
    }
  }

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsTransaction value="details" />
          {!isLoading ?
            <TransactionByIdContent
              transaction={transaction}
              onPrint={handlePrint}
              isExport={isExport}
              isDialogOpen={isDialogOpen}
              setIsDialogOpen={setIsDialogOpen}
            />
            : <Skeleton className="w-full h-full" />}
        </div>
      </div>
    </PlatformTemplate>
  )
}

const TransactionByIdContent = ({
  transaction,
  onPrint,
  isExport,
  isDialogOpen,
  setIsDialogOpen
}: {
  transaction: any,
  onPrint: () => void,
  isExport: boolean
  isDialogOpen: boolean
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const ISPB = process.env.REACT_APP_BANK_ISPB
  const navigate = useNavigate()

  if (!transaction) {
    return (
      <div className="w-full flex flex-col items-center justify-center gap-5 py-10">
        <div className="w-full px-20">
          <div id="transaction" className="w-full h-[300px] flex items-center justify-center">
            <Loader2 className="mr-2 h-12 w-12 animate-spin" />
          </div>
        </div>
      </div>
    )
  }

  interface tProps {
    key: string
    value: string
  }

  const tMethod: tProps[] = [
    {
      key: 'Consolidate',
      value: 'Saldo do dia'
    },
    {
      key: 'QRCodeDynamic',
      value: 'QRCode Dinâmico'
    },
    {
      key: 'QRCodeStatic',
      value: 'QRCode Estático'
    },
    {
      key: 'Dict',
      value: 'Dict'
    }, {
      key: 'Manual',
      value: 'Manual'
    }, {
      key: 'TarProcPix',
      value: 'TarProcPix'
    }
  ]

  const tStatus: tProps[] = [
    {
      key: 'CheckingAccount',
      value: 'Conta Corrente'
    },
    {
      key: 'Salary',
      value: 'Conta Salário'
    },
    {
      key: 'Savings',
      value: 'Conta Poupança'
    },
    {
      key: 'Transactional',
      value: 'Conta Transacional'
    }
  ]

  const tType: tProps[] = [
    {
      key: 'Debit',
      value: 'Débito'
    },
    {
      key: 'Credit',
      value: 'Crédito'
    },
    {
      key: 'RefundDebit',
      value: 'Reembolso de Débito'
    },
    {
      key: 'RefundCredit',
      value: 'Reembolso de Crédito'
    },
    {
      key: 'FeeDebit',
      value: 'Taxa de Débito'
    },
    {
      key: 'FeeCredit',
      value: 'Taxa de Crédito'
    }
  ]

  return (
    <div className="w-full flex flex-col items-center justify-center gap-5 py-10">
      <div className="w-full px-20 overflow-x-auto">
        <div className="w-full flex items-center justify-end gap-2">
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
              {transaction.type === 'Debit' &&
                <Button
                  variant="outline"
                  className="flex gap-2"
                  onClick={() => setIsDialogOpen(true)}
                >
                  Imprimir comprovante
                  <Printer size={20} />
                </Button>}
            </DialogTrigger>
            <DialogContent className="w-full max-w-[1150px] max-h-[750px] py-14 flex flex-col items-center overflow-y-auto">
              <div id="transaction" className="w-[950px] h-full shadow shadow-neutral-500 p-12 flex flex-col gap-10">
                <div className="w-full flex flex-col items-center gap-4">
                  <div className="w-full flex items-center justify-start">
                    <img src={Logo} className="w-[100px] h-auto" alt="Lyra logo" />
                  </div>
                  <h1 className="text-[#091750] font-semibold text-lg">Comprovante de Transferência</h1>
                  <div className="w-full border border-[#CBD5E1]" />
                  <div className="h-12 w-full flex items-center justify-between px-8 bg-[#E8EBF9] border border-[#CBD5E1] rounded-xl">
                    <p className="font-semibold text-[#091750]">Valor</p>
                    <p className="text-lg font-semibold text-[#091750]">
                      {formatToBRL(transaction.amount)}
                    </p>
                  </div>
                  <div className="h-12 w-full flex items-center justify-between px-8 border border-[#CBD5E1] rounded-xl">
                    <p className="font-semibold text-[#091750]">Identificador E2E</p>
                    <p className="text-black">{transaction.endToEndIdentification}</p>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-2 items-center justify-center">
                  <div className="w-full flex gap-4 items-center justify-start">
                    <div className="h-7 w-7 flex items-center justify-center rounded-full bg-[#091750] text-white">
                      <ReceiptText size={18} />
                    </div>
                    <h1 className="text-[#091750] font-semibold">Dados da Transação</h1>
                  </div>
                  <div className="w-full border border-[#CBD5E1] rounded-full" />
                  <div className="w-full flex flex-col gap-5 py-3 px-8">
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">Data de Criação</h1>
                      <p>{convertUTCToUserTimezone(transaction.createDate, getUserTimezoneOffset())}</p>
                    </div>
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">Método de pagamento</h1>
                      <p>
                        {tMethod.map((translation, index) => {
                          if (transaction.method === translation.key) {
                            return <p key={index}>{translation.value}</p>
                          }
                        })}
                      </p>
                    </div>
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">Tipo</h1>
                      <p>
                        {tType.map((translation, index) => {
                          if (transaction.type === translation.key) {
                            return <p key={index}>{translation.value}</p>
                          }
                        })}
                      </p>
                    </div>
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">Status</h1>
                      <p>
                        {transaction.status === 'Completed' && (
                          <div className="flex gap-1 items-center justify-center py-1 px-4 rounded-full bg-[#B1EBD0] text-[#02502c] font-semibold">
                            <div className="h-5 w-5 flex items-center justify-center rounded-full bg-[#02502c]">
                              <Check className="text-[#B1EBD0]" size={12} />
                            </div>
                            Completo
                          </div>
                        )}
                      </p>
                    </div>
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">ID da Transação</h1>
                      <p>{transaction.transactionId}</p>
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-2 items-center justify-center">
                  <div className="w-full flex gap-4 items-center justify-start">
                    <div className="h-7 w-7 flex items-center justify-center rounded-full bg-[#091750] text-white">
                      <HandCoins size={18} />
                    </div>
                    <h1 className="text-[#091750] font-semibold">Conta de origem</h1>
                  </div>
                  <div className="w-full border border-[#CBD5E1] rounded-full" />
                  <div className="w-full flex flex-col gap-5 py-3 px-8">
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">Nome</h1>
                      <p>{transaction.debtorName}</p>
                    </div>
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">Agência</h1>
                      <p>{transaction.debtorAccountBranch}</p>
                    </div>
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">Conta</h1>
                      <p>{transaction.debtorAccountNumber}</p>
                    </div>
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">Tipo de conta</h1>
                      <p>
                        {tStatus.map((translation, index) => {
                          if (transaction.debtorAccountType === translation.key) {
                            return <p key={index}>{translation.value}</p>
                          }
                        })}
                      </p>
                    </div>
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">Identificador</h1>
                      <p>{transaction.debtorIdentification}</p>
                    </div>
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">ISPB</h1>
                      <p>{transaction.debtorIspb}</p>
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-2 items-center justify-center">
                  <div className="w-full flex gap-4 items-center justify-start">
                    <div className="h-7 w-7 flex items-center justify-center rounded-full bg-[#091750] text-white">
                      <Wallet size={18} />
                    </div>
                    <h1 className="text-[#091750] font-semibold">Conta de destino</h1>
                  </div>
                  <div className="w-full border border-[#CBD5E1] rounded-full" />
                  <div className="w-full flex flex-col gap-5 py-3 px-8">
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">Nome</h1>
                      <p>{transaction.creditorName}</p>
                    </div>
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">Agência</h1>
                      <p>{transaction.creditorAccountBranch}</p>
                    </div>
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">Conta</h1>
                      <p>{transaction.creditorAccountNumber}</p>
                    </div>
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">Tipo de conta</h1>
                      <p>
                        {tStatus.map((translation, index) => {
                          if (transaction.creditorAccountType === translation.key) {
                            return <p key={index}>{translation.value}</p>
                          }
                        })}
                      </p>
                    </div>
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">Identificador</h1>
                      <p>{transaction.creditorIdentification}</p>
                    </div>
                    <div className="w-full flex items-center justify-between">
                      <h1 className="font-semibold text-[#091750]">ISPB</h1>
                      <p>{transaction.creditorIspb}</p>
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-center">
                    <img
                      src={Logo}
                      className="w-[80px] h-auto opacity-50"
                      alt="Logo Lyra"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full h-24 mt-10 flex items-center justify-center">
                <Button
                  className="w-[250px] h-12 flex items-center justify-center gap-2 text-white font-medium bg-blue-950 hover:bg-blue-900 rounded-md"
                  onClick={onPrint}
                  variant="default"
                  disabled={isExport}
                >
                  {isExport ? "Imprimindo..." : <>Imprimir<Printer size={15} /></>}
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
        <div className="shadow-md w-full min-w-[700px] flex flex-col gap-7 p-10">
          <div className="w-full flex items-center justify-between border-b border-gray-200 py-8">
            <p className="font-medium">Identificador da Transação:</p>
            <div className="flex items-center justify-end gap-2">
              <Fingerprint size={20} className="text-zinc-950" />
              <p className="text-zinc-950">{getPath()}</p>
            </div>
          </div>
          <div className="flex flex-col gap-2 pb-2 border-b-2 border-slate-400 border-dotted text-gray-600">
            <h1 className="text-sky-800 font-semibold">Informações Gerais da Transação</h1>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Data de Criação:</p>
              <p>{convertUTCToUserTimezone((transaction.createDate), getUserTimezoneOffset())}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Método de Transação:</p>
              <p>
                {tMethod.map((translation, index) => {
                  if (transaction.method === translation.key) {
                    return <p key={index}>{translation.value}</p>
                  }
                })}
              </p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Etapa:</p>
              <p>
                {tStatus.map((translation, index) => {
                  if (transaction.creditorAccountType === translation.key) {
                    return <p key={index}>{translation.value}</p>
                  }
                })}
              </p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Tipo:</p>
              <p>
                {tType.map((translation, index) => {
                  if (transaction.type === translation.key) {
                    return <p key={index}>{translation.value}</p>
                  }
                })}
              </p>
            </div>
            <div className="w-full flex items-center justify-between py-1">
              <p className="font-medium">Identificador E2E:</p>
              <p>{transaction.endToEndIdentification}</p>
            </div>
          </div>
          <div className="flex flex-col gap-2 pb-2 border-b-2 border-slate-400 border-dotted text-gray-600">
            <h1 className="text-sky-800 font-semibold">Informações do Credor</h1>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Nome:</p>
              <p>{transaction.creditorName}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Agência:</p>
              <p>{transaction.creditorAccountBranch}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Conta:</p>
              <p>{transaction.creditorAccountNumber}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Tipo de Conta:</p>
              <p>
                {tStatus.map((translation, index) => {
                  if (transaction.debtorAccountType === translation.key) {
                    return <p key={index}>{translation.value}</p>
                  }
                })}
              </p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Identificador:</p>
              <p>{transaction.creditorIdentification}</p>
            </div>
            <div className="w-full flex items-center justify-between py-1">
              <p className="font-medium">ISPB</p>
              <p>{transaction.creditorIspb}</p>
            </div>
          </div>
          <div className="flex flex-col gap-2 pb-2 border-b-2 border-slate-400 border-dotted text-gray-600">
            <h1 className="text-sky-800 font-semibold">Informações do Devedor</h1>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Nome:</p>
              <p>{transaction.debtorName}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Agência:</p>
              <p>{transaction.debtorAccountBranch}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Conta:</p>
              <p>{transaction.debtorAccountNumber}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Tipo de Conta:</p>
              <p>
                {tStatus.map((translation, index) => {
                  if (transaction.debtorAccountType === translation.key) {
                    return <p key={index}>{translation.value}</p>
                  }
                })}
              </p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Identificador:</p>
              <p>{transaction.debtorIdentification}</p>
            </div>
            <div className="w-full flex items-center justify-between py-1">
              <p className="font-medium">ISPB:</p>
              <p>{transaction.debtorIspb}</p>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <h1 className="font-semibold">{formatToBRL(transaction.amount)}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}
