import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table"
import {
  ChevronDown,
  Fingerprint,
  Landmark,
  Library,
  MoreHorizontal,
  RefreshCw,
  Route
} from "lucide-react"
import { Button } from "../../../../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu"
import { TransactionProps, Filters } from "../page"
import { TableFiltersTransaction } from "./table-filter-transaction"
import { formatToBRL } from "../../../../utils/helpers/formated"
import { Input } from "../../../../components/ui/input"
import {
  useReactTable,
  ColumnDef,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender
} from "@tanstack/react-table"
import { Skeleton } from "../../../../components/ui/skeleton"
import { getTransactions } from "../../../../api/endpoints/transaction"
import { convertUTCToUserTimezone } from "../../../../utils/helpers/date"
import { getUserTimezoneOffset } from "../../../../utils/storage/auth"

const ActionCell = ({ transaction }: { transaction: TransactionProps }) => {
  const navigate = useNavigate()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">Abrir Menu</span>
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Ações</DropdownMenuLabel>
        <DropdownMenuItem
          onClick={() => navigator.clipboard.writeText(transaction.transactionId)}
        >
          <Fingerprint size={15} className="mr-2" />Copiar ID da Transação
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate(`/transaction/details/${transaction.transactionId}`)}><Library size={15} className="mr-2" />Ver Detalhamentos</DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate(`/account/details/${transaction.accountId}`)}><Landmark size={15} className="mr-2" />Ir para Conta</DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate(`/transaction/trajectory/${transaction.transactionId}`)}><Route size={15} className="mr-2" />Trajetória da Transação</DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate(`/transaction/end-to-end-trajectory/${transaction.transactionId}`)}><Route size={15} className="mr-2" />Trajetória do E2E</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const columns: ColumnDef<TransactionProps>[] = [
  {
    accessorKey: "createDate",
    header: "Data de Criação",
    cell: ({ row }) => (
      <div className="w-[145px]">{convertUTCToUserTimezone(row.getValue("createDate"), getUserTimezoneOffset())}</div>
    ),
  },
  {
    accessorKey: "debtorName",
    header: "Devedor",
    cell: ({ row }) => (
      <div className="w-[100px]">{row.getValue("debtorName")}</div>
    ),
  },
  {
    accessorKey: "debtorIspb",
    header: "ISPB Devedor",
    cell: ({ row }) => (
      <div>{row.getValue("debtorIspb")}</div>
    ),
  },
  {
    accessorKey: "creditorName",
    header: "Credor",
    cell: ({ row }) => (
      <div className="w-[250px]">{row.getValue("creditorName")}</div>
    ),
  },
  {
    accessorKey: "creditorAccountNumber",
    header: "Conta do Credor",
    cell: ({ row }) => (
      <div>{row.getValue("creditorAccountNumber")}</div>
    ),
  },
  {
    accessorKey: "creditorIspb",
    header: "ISPB Credor",
    cell: ({ row }) => (
      <div>{row.getValue("creditorIspb")}</div>
    ),
  },
  {
    accessorKey: "type",
    header: "Tipo",
    cell: ({ row }) => {
      const tType = [
        {
          key: 'Debit',
          value: 'Débito'
        },
        {
          key: 'Credit',
          value: 'Crédito'
        },
        {
          key: 'RefundDebit',
          value: 'Reembolso de Débito'
        },
        {
          key: 'RefundCredit',
          value: 'Reembolso de Crédito'
        },
        {
          key: 'FeeDebit',
          value: 'Taxa de Débito'
        },
        {
          key: 'FeeCredit',
          value: 'Taxa de Crédito'
        }]

      return (
        <div className="w-[100px]">
          {tType.map((translation, index) => {
            if (row.getValue("type") === translation.key) {
              return <p key={index}>{translation.value}</p>
            }
          })}
        </div>
      )
    },
  },
  {
    accessorKey: "method",
    header: "Método",
    cell: ({ row }) => {
      const tMethod = [
        {
          key: 'Consolidate',
          value: 'Saldo do dia'
        },
        {
          key: 'QRCodeDynamic',
          value: 'QRCode Dinâmico'
        },
        {
          key: 'QRCodeStatic',
          value: 'QRCode Estático'
        },
        {
          key: 'Dict',
          value: 'Dict'
        }, {
          key: 'Manual',
          value: 'Manual'
        }, {
          key: 'TarProcPix',
          value: 'TarProcPix'
        }
      ]

      return (
        <div className="w-[200px]">
          {tMethod.map((translation, index) => {
            if (row.getValue("method") === translation.key) {
              return <p key={index}>{translation.value}</p>
            }
          })}
        </div>
      )
    },
  },
  {
    accessorKey: "amount",
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Valor
        <ChevronDown className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => (
      <div>{formatToBRL(row.getValue("amount"))}</div>
    ),
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => (
      <div className="w-[100px]">{row.getValue("status") === "Completed" ? "Completada" : row.getValue("status") === 'Rejected' ? 'Rejeitada' : 'Pendente'}</div>
    ),
  },
  {
    accessorKey: "endToEndIdentification",
    header: "E2E",
    cell: ({ row }) => (
      row.getValue("endToEndIdentification") && (
        <Input
          value={row.getValue("endToEndIdentification")}
          className="w-[200px]"
        />
      )
    ),
  },
  {
    accessorKey: "txId",
    header: "TxID",
    cell: ({ row }) => (
      row.getValue("txId") && (
        <Input
          value={row.getValue("txId")}
          className="w-[200px]"
        />
      )
    ),
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => <ActionCell transaction={row.original} />,
  },
]

export default function TableTransactions({ transactions, setTransactions, pageIndex, setPageIndex, pageSize, setPageSize, totalPages, setTotalPages, filters, setFilters, appliedFilters, setAppliedFilters }:
  {
    transactions: TransactionProps[],
    setTransactions: any,
    pageIndex: number,
    setPageIndex: any,
    pageSize: number,
    setPageSize: any,
    totalPages: number,
    setTotalPages: any,
    filters: Filters,
    setFilters: any,
    appliedFilters: Filters,
    setAppliedFilters: any,
  }) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = useState({})

  const [onRefresh, setOnRefresh] = useState<boolean>(false)
  const table = useReactTable({
    data: transactions,
    columns,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnVisibility,
      rowSelection,
    },
  })

  const handleRefreshTable = async (pageIndex: number, pageSize: number, filters: Filters) => {
    setOnRefresh(true)
    const response = await getTransactions({ pageIndex, pageSize, ...filters })
    setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
    if (response?.data?.items) {
      setTransactions(response?.data?.items)
      setOnRefresh(false)
    } else {
      setTransactions([])
      setOnRefresh(false)
    }
  }

  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <div className="w-full h-auto flex items-center gap-2">
          <TableFiltersTransaction
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            setTotalPages={setTotalPages}
            filters={filters}
            setFilters={setFilters}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            setTransactions={setTransactions}
          />
        </div>
        <DropdownMenu>
          <div className="flex items-center justify-center gap-2">
            <Button onClick={() => handleRefreshTable(pageIndex, pageSize, filters)} variant="secondary">
              <RefreshCw size={20} className={`${onRefresh && 'animate-spin'}`} />
            </Button>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                Colunas <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
          </div>
          <DropdownMenuContent align="end">
            {table.getAllColumns().map((column) => (
              <DropdownMenuCheckboxItem
                key={column.id}
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
              >
                {column.id}
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        {!onRefresh ?
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead
                      key={header.id}
                      className="py-2"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody className="overflow-x-auto">
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    Sem resultados
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table> :
          <Skeleton className="w-full h-[525px]" />}
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="font-semibold text-sm text-zinc-600">
          {pageIndex} de {totalPages}
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setPageIndex((old: number) => Math.max(old - 1, 1))}
            disabled={pageIndex === 1}
          >
            Anterior
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setPageIndex((old: number) => old + 1)}
            disabled={pageIndex === totalPages}
          >
            Próximo
          </Button>
        </div>
      </div>
    </div>
  )
}
