import api from ".."
import { HeadersAccessToken } from "../config"

/**
 * => Função para realizar a transferência de taxa.
 * @param accountId ID da conta.
 * @param amount Valor da taxa.
 * @returns Resposta da transferência ou erro.
 */
export const transferFee = async ({ accountId, amount }: { accountId: string; amount: number }) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/Admin/transfer-fee`
  const data = {
    accountId,
    amount,
  }
  try {
    return await api.post(url, data, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}
