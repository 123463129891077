import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table"
import {
  ColumnDef,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ChevronDown, File, Fingerprint, Library, MoreHorizontal, RefreshCw, Ruler, Settings, Target, Wallet } from "lucide-react"
import { Button } from "../../../../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu"
import { AccountProps, Filters } from "../page"
import { TableFiltersAccount } from "./table-filter-account"
import { formatToBRL } from "../../../../utils/helpers/formated"
import { CaretSortIcon } from "@radix-ui/react-icons"
import { getAccounts } from "../../../../api/endpoints/account"
import { Skeleton } from "../../../../components/ui/skeleton"
import { convertUTCToUserTimezone } from "../../../../utils/helpers/date"
import { getUserTimezoneOffset } from "../../../../utils/storage/auth"

const ActionCell = ({ account, onAction, onActionTransferFee }: { account: any; onAction: (id: string, ownerId: string) => void, onActionTransferFee: (id: string) => void }) => {
  const navigate = useNavigate()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">Abrir Menu</span>
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Ações</DropdownMenuLabel>
        <DropdownMenuItem
          onClick={() => navigator.clipboard.writeText(account.accountId)}
        >
          <Fingerprint size={15} className="mr-2" />Copiar ID da Conta
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => onAction(account.accountId, account.ownerId)}>
          <Wallet size={15} className="mr-2" />
          Transferir
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onActionTransferFee(account.accountId)}>
          <Wallet size={15} className="mr-2" />
          Transferir Taxa
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate(`/account/details/${account.accountId}`)}><Library size={15} className="mr-2" />Ver Detalhamentos</DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate(`/account/extract/${account.accountId}`)}><File size={15} className="mr-2" />Extrato</DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate(`/account/settings/${account.accountId}`)}><Settings size={15} className="mr-2" />Configurações</DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate(`/account/activities/${account.accountId}`)}><Target size={15} className="mr-2" />Atividades</DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate(`/account/risk/${account.accountId}`)}><Ruler size={15} className="mr-2" />Regras de Risco</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export interface AccountFilterProps {
  accountId: string
  accountNumber: string
  ownerId: string
  ownerName: string
  ownerDocument: string
  status: '' | 'Active' | 'Inactive' | 'Blocked'
  type: '' | 'checkingAccount' | 'salary' | 'savings' | 'transactional'
  [key: string]: string
}

export default function TableAccounts({ accounts, setAccounts, pageIndex, setPageIndex, pageSize, setPageSize, totalPages, setTotalPages, filters, setFilters, appliedFilters, setAppliedFilters, onAction, onActionTransferFee }:
  {
    accounts: AccountProps[],
    setAccounts: any,
    pageIndex: number,
    setPageIndex: any,
    pageSize: number,
    setPageSize: any,
    totalPages: number,
    setTotalPages: any,
    filters: Filters,
    setFilters: any,
    appliedFilters: Filters,
    setAppliedFilters: any,
    onActionTransferFee: (id: string) => void;
    onAction: (id: string, ownerId: string) => void;
  }) {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})

  const columns: ColumnDef<AccountProps>[] = [{
    accessorKey: "branch",
    header: "Agência",
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("branch")}</div>
    ),
  },
  {
    accessorKey: "accountNumber",
    header: "Número da Conta",
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("accountNumber")}</div>
    ),
  },
  {
    accessorKey: "ownerName",
    header: "Cliente",
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("ownerName")}</div>
    ),
  },
  {
    accessorKey: "ownerDocument",
    header: "Documento",
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("ownerDocument")}</div>
    ),
  },
  {
    accessorKey: "balance",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Saldo
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <div className="capitalize">{formatToBRL(row.getValue("balance"))}</div>
    ),
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => (
      <div className={`capitalize ${row.getValue("status") === "Active" ? "text-green-500 font-medium" : "text-red-500 font-medium"}`}>{row.getValue("status") === "Active" ? "Ativo" : "Bloqueado"}</div>
    ),
  },
  {
    accessorKey: "createDate",
    header: "Data de Criação",
    cell: ({ row }) => (
      <div>{convertUTCToUserTimezone(row.getValue("createDate"), getUserTimezoneOffset())}</div>
    ),
  },
  {
    accessorKey: "updateDate",
    header: "Data de Atualização",
    cell: ({ row }) => (
      <div>{convertUTCToUserTimezone(row.getValue("updateDate"), getUserTimezoneOffset())}</div>
    ),
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => <ActionCell
      account={row.original} onAction={onAction} onActionTransferFee={onActionTransferFee} />,
  },
  ]

  const [onRefresh, setOnRefresh] = useState<boolean>(false)
  const table = useReactTable({
    data: accounts,
    columns,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnVisibility,
      rowSelection,
    },
  })

  const handleRefreshTable = async (pageIndex: number, pageSize: number, filters: Filters) => {
    setOnRefresh(true)
    const response = await getAccounts({ pageIndex, pageSize, ...filters })
    setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
    if (response?.data?.items) {
      setAccounts(response?.data?.items)
      setOnRefresh(false)
    } else {
      setAccounts([])
      setOnRefresh(false)
    }
  }

  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <div className="w-full h-auto flex items-center gap-2">
          <TableFiltersAccount
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            setTotalPages={setTotalPages}
            filters={filters}
            setFilters={setFilters}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            setAccounts={setAccounts}
          />
        </div>
        <DropdownMenu>
          <div className="flex items-center justify-center gap-2">
            <Button onClick={() => handleRefreshTable(pageIndex, pageSize, filters)} variant="secondary">
              <RefreshCw size={20} className={`${onRefresh && 'animate-spin'}`} />
            </Button>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                Colunas <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
          </div>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                )
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        {!onRefresh ?
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    Sem resultados
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table> :
          <Skeleton className="w-full h-[525px]" />}
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="font-semibold text-sm text-zinc-600">
          {pageIndex} de {totalPages}
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setPageIndex((old: number) => Math.max(old - 1, 1))}
            disabled={pageIndex === 1}
          >
            Anterior
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setPageIndex((old: number) => old + 1)}
            disabled={pageIndex === totalPages}
          >
            Próximo
          </Button>
        </div>
      </div>
    </div>
  )
}
